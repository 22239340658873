<template>
  <div id="app">
    <v-app id="inspire">
      <v-card
        class="mx-auto"
        width="100%"
        max-width="400"
      >
        <v-card-title class="title font-weight-regular justify-space-between">
          <span>{{ currentTitle }}</span>
          <v-avatar
            color="primary lighten-2"
            class="subheading white--text"
            size="24"
            v-text="step"
          ></v-avatar>
        </v-card-title>
        <v-form
          ref="registerForm"
        >
          <v-window v-model="step">
            <v-window-item :value="1">
              <v-card-text>
                <v-text-field
                  v-model="prefix"
                  label="Prefix: Ms., Mrs., Mr., etc "
                  required
                ></v-text-field>
                <v-text-field
                  v-model="suffix"
                  label="Suffix: I, II, III, etc"
                  required
                ></v-text-field>
                <v-text-field
                  v-model="firstName"
                  label="First Name"
                  required
                ></v-text-field>
                <v-text-field
                  v-model="lastName"
                  label="Last Name"
                  required
                ></v-text-field>
                <v-text-field
                  v-model="phoneNumber"
                  label="Phone Number: 1 1239876540"
                  required
                ></v-text-field>
              </v-card-text>
            </v-window-item>

            <v-window-item :value="2">
              <v-card-text>
                <v-select
                  v-model="rolePackage"
                  :items="rolePackageArray"
                  label="Position"
                >
                </v-select>
                <v-text-field
                  v-model="email"
                  :rules="emailRules"
                  label="Email"
                  required
                ></v-text-field>
                <site-select
                  :site="site"
                  @site-select="updateSite"
                >
                </site-select>
              </v-card-text>
            </v-window-item>

            <v-window-item :value="3">
              <div class="pa-4 text-center">
                <v-img
                  class="mb-4"
                  contain
                  height="128"
                  src="https://www.svgrepo.com/show/43086/success.svg"
                ></v-img>
                <h3 class="title font-weight-light mb-2">
                  Welcome to DetainEHR
                </h3>
                <span class="caption grey--text">We've sent a confirmation link to your email address, it needs to be confirmed prior to logging in</span>
              </div>
            </v-window-item>
          </v-window>

          <v-divider></v-divider>

          <v-card-actions>
            <v-btn
              :disabled="step === 1"
              text
              @click="step--"
            >
              Back
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              :disabled="step === 3"
              color="primary"
              depressed
              @click="formSubmit()"
            >
              Next
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-app>
  </div>
</template>

<script>
import SiteSelect from '@/components/site/SiteSelect.vue'
import { ref, onUnmounted } from '@vue/composition-api'
import authenticationService from '@/services/AuthenticationService'
import useAuthenticationService from '@/store/useAuthenticationService'
import store from '@/store'
import { emailValidator, required } from '@core/utils/validation'

export default {
  components: {
    SiteSelect,
  },
  data: () => ({
    site: null,
    siteId: '',
    globalUser: false,
    registerSiteId: '',
    step: 1,
    rolePackage: null,
    modificationsMade: 'Registered New User',
    firstName: '',
    lastName: '',
    suffix: '',
    prefix: '',
    title: '',
    honorific: '',
    npiNumber: '',
    deaNumber: '',
    profilePicture: '',
    phoneNumber: '',
    twoFactorEnabled: false,
    recordedOn: Date.now(),
    recordedBy: 'Site Admin',
    email: '',
    emailRules: [
      v => !!v || 'Email is required',
      v => v.length >= 5 || 'The e-mail address must contain at least 5 characters',
      v => v.length <= 50 || 'The e-mail address cannot be longer than 50 characters',
      v => /.+@.+/.test(v) || 'Please enter a valid email address',
    ],
  }),
  setup() {
    const REG_APP = 'app-auth'

    // Register module
    if (!store.hasModule(REG_APP)) {
      store.registerModule(REG_APP, authenticationService)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(REG_APP)) store.unregisterModule(REG_APP)
    })

    const { newUserDTO, registerUser, rolePackage, siteId, rolePackageArray, getRolePackageArray } =
      useAuthenticationService()

    getRolePackageArray()

    const registerForm = ref(null)

    const RedirectToDashboard = () => {
      try {
        this.$router.push('/dashboards/home').catch(() => {})
      } catch {
        console.log('Redirection Failed')
      }
    }

    return {
      rolePackageArray,
      getRolePackageArray,
      registerForm,

      newUserDTO,
      registerUser,
      rolePackage,
      siteId,
      RedirectToDashboard,

      validators: {
        required,
        emailValidator,
      },
    }
  },
  computed: {
    currentTitle() {
      switch (this.step) {
        case 1:
          return 'Employee Information'
        case 2:
          return 'Employee Details'
        default:
          return 'Account created'
      }
    },
  },
  methods: {
    updateSite(siteDTO) {
      this.site = siteDTO.value
      this.siteId = siteDTO.value.id
    },
    async formSubmit() {
      if (this.step < 3) this.step++
      if (this.step === 3) {
        const newUserDTO = {
          Prefix: this.prefix,
          Honorific: this.prefix,
          Suffix: this.suffix,
          FirstName: this.firstName,
          LastName: this.lastName,
          PhoneNumber: this.phoneNumber,
          Title: this.rolePackage,
          Email: this.email,
          RolePackage: this.rolePackage,
          TwoFactorEnabled: false,
          ProfilePicture: '',
          SiteId: this.siteId,
          NPINumber: '',
          DEANumber: '',
          IsActive: true,
          RecordedBy: '',
          ModifiedBy: '',
          APIKey: null,
          InactivatedBy: null,
          InactivatedDate: null,
          RecordedDate: new Date().toISOString().substr(0, 10),
          ModifiedDate: new Date().toISOString().substr(0, 10),
        }

        if (this.rolePackage.value !== 'global' || this.rolePackage.value !== 'ach administrator') {
          this.registerSiteId = this.siteId
        } else {
          newUserDTO.SiteId = this.registerSiteId
        }

        await this.registerUser(newUserDTO)

        this.RedirectToDashboard()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
